// action types
import ProfileService from '@/core/services/profile.service'

export const UPDATE_PERSONAL_INFO = 'updateUserPersonalInfo'
export const UPDATE_ACCOUNT_INFO = 'updateUserAccountInfo'

// mutation types
export const SET_PERSONAL_INFO = 'setPersonalInfo'
export const SET_ACCOUNT_INFO = 'setAccountInfo'

const state = {
    user_personal_info: {
        id: '',
        name: '',
        surname: '',
        email: '',
        phone: '',
        photo: 'media/users/300_21.jpg',
    },
    user_account_info: {
        role: null,
        company_api_endpoint: null,
        company_id: null,
        company_oib: null,
        company_active_module: null,
        activeModuleComponents: null,
    },
}

const getters = {
    currentUserPersonalInfo(state) {
        return state.user_personal_info
    },

    currentUserAccountInfo(state) {
        return state.user_account_info
    },

    currentUserPhoto(state) {
        return state.user_personal_info.photo
    },

    currentUserRole(state) {
        return state.user_account_info.role
    },

    currentUserCompany(state) {
        return state.user_account_info.company_api_endpoint
    },

    currentUserCompanyId(state) {
        return state.user_account_info.company_id
    },

    currentUserCompanyOib(state) {
        return state.user_account_info.company_oib
    },

    currentUserCompanyModule(state) {
        return state.user_account_info.company_active_module
    },

    currentActiveModuleComponents(state) {
        return state.user_account_info.activeModuleComponents
    },
}

const actions = {
    [UPDATE_PERSONAL_INFO](context, payload) {
        context.commit(SET_PERSONAL_INFO, payload)
    },
    [UPDATE_ACCOUNT_INFO](context, payload) {
        context.commit(SET_ACCOUNT_INFO, payload)
    },
}

const mutations = {
    [SET_PERSONAL_INFO](state, user_personal_info) {
        state.user_personal_info.id = user_personal_info.id
        state.user_personal_info.name = user_personal_info.name
        state.user_personal_info.surname = user_personal_info.surname
        state.user_personal_info.email = user_personal_info.email
        state.user_personal_info.phone = user_personal_info.phone

        ProfileService.saveProfilePersonalInfo(state.user_personal_info)
    },
    [SET_ACCOUNT_INFO](state, user_account_info) {
        state.user_account_info.role = user_account_info.role
        state.user_account_info.company_api_endpoint = user_account_info['@id']
        state.user_account_info.company_id = user_account_info.id
        state.user_account_info.company_oib = user_account_info.oib
        state.user_account_info.company_active_module =
            user_account_info.activeModules
        state.user_account_info.activeModuleComponents =
            user_account_info.activeModuleComponents

        ProfileService.saveProfileAccountInfo(state.user_account_info)
    },
}

export default {
    state,
    actions,
    mutations,
    getters,
}
