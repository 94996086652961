import ApiService from '@/core/services/api.service'
import JwtService from '@/core/services/jwt.service'
import ProfileService from '@/core/services/profile.service'
import {
    UPDATE_PERSONAL_INFO,
    UPDATE_ACCOUNT_INFO,
    SET_PERSONAL_INFO,
    SET_ACCOUNT_INFO,
} from '@/core/services/store/profile.module'

// action types
export const VERIFY_AUTH = 'verifyAuth'
export const LOGIN = 'login'
export const LOGOUT = 'logout'

// mutation types
export const PURGE_AUTH = 'logOut'
export const SET_AUTH = 'setUser'
export const SET_PASSWORD = 'setPassword'
export const SET_ERROR = 'setError'

const state = {
    errors: null,
    user: {},
    isAuthenticated: !!JwtService.getToken(),
}

const getters = {
    errors(state) {
        return state.errors
    },
    currentUser(state) {
        return state.user
    },
    isAuthenticated(state) {
        return state.isAuthenticated
    },
}

const actions = {
    [LOGIN](context, credentials) {
        return new Promise((resolve) => {
            ApiService.post('authentication_token', credentials)
                .then((response1) => {
                    context.commit(SET_AUTH, response1.data.token)

                    ApiService.get('/users', `?email=${credentials.email}`).then(
                        (response2) => {
                            const userRole = response2.data['hydra:member'][0].roles[0]
                            const company = response2.data['hydra:member'][0].company.id
                            // const company = response2.data['hydra:member'][0].company.replace(
                            //   '/api/v1/companies/',
                            //   ''
                            // )

                            this.dispatch(
                                UPDATE_PERSONAL_INFO,
                                response2.data['hydra:member'][0]
                            )

                            ApiService.get('/companies', company).then((response3) => {
                                response3.data.role = userRole
                                this.dispatch(UPDATE_ACCOUNT_INFO, response3.data)

                                resolve(response1.data)
                            })
                        }
                    )
                })
                .catch(({response}) => {
                    context.commit(SET_ERROR, response.data.message)
                })
        })
    },
    [LOGOUT](context) {
        context.commit(PURGE_AUTH)
    },
    [VERIFY_AUTH](context) {
        if (JwtService.getToken()) {
            context.commit(SET_AUTH, JwtService.getToken())

            if (ProfileService.getProfilePersonalInfo()) {
                context.commit(
                    SET_PERSONAL_INFO,
                    ProfileService.getProfilePersonalInfo()
                )
            }

            if (ProfileService.getProfileAccountInfo()) {
                const data = ProfileService.getProfileAccountInfo()
                context.commit(SET_ACCOUNT_INFO, {
                    role: data.role,
                    '@id': data.company_api_endpoint,
                    id: data.company_id,
                    oib: data.company_oib,
                    activeModules: data.company_active_module,
                    activeModuleComponents: data.activeModuleComponents,
                })
            }
        } else {
            context.commit(PURGE_AUTH)
        }
    },
}

const mutations = {
    [SET_ERROR](state, error) {
        state.errors = error
    },
    [SET_AUTH](state, token) {
        state.isAuthenticated = true
        state.errors = null
        JwtService.saveToken(token)
    },
    [SET_PASSWORD](state, password) {
        state.user.password = password
    },
    [PURGE_AUTH](state) {
        state.isAuthenticated = false
        state.user = {}
        state.errors = null
        JwtService.destroyToken()
        ProfileService.destroyProfilePersonalInfo()
        ProfileService.destroyProfileAccountInfo()
    },
}

export default {
    state,
    actions,
    mutations,
    getters,
}
